@import "styles";


.jobs {
  .page-title-section {
    h1 {
      line-height: 1.5em;
      margin-bottom: 1em;
    }

    .photo {
      img {
        width: 300px;
      }
    }
  }

  .page-jobs-content {
    padding-bottom: 20px;

    p {
      font-size: 1.5em;
      line-height: 1.5em;

      &.lead {
        font-weight: bold;
      }
    }
  }

  .page-jobs-listings {
    .header.row {
      h2 {
        margin-bottom: 0;
      }

      h5 {
        font-size: 1rem;
        text-transform: uppercase;
        color: #999;
      }
    }

    .listing {
      // margin: 0;
      margin: 10px;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0,0,0,.2);

      h3 {
        font-size: 1.5em;
        margin-bottom: 0;
      }

      h5 {
        font-size: 0.9rem;
        text-transform: uppercase;
        color: #999;
      }
    }
  }
}
